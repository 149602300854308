import { useEffect, useState } from 'react';
import Menu from '../menu/Menu';
import Timer from '../timer/Timer';
import Topbar from '../topbar/Topbar'
import './home.scss'

type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};

export default function Home({setIsLoading}:PropsType) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [showMint, setShowMint] = useState(false)

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount>=1){
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);

    return (
        <div className="home" id = "home">
            <Topbar menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <Menu menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <div className="homeContent">
                <div className="homeWrapper">
                    <div className="homeLeft" data-aos="fade-right">
                    <h1>Welcome to the Powerball of Crypto..</h1>
                        <p>We turn Apes & Degens into shareholders. Countless winners, No losers.</p>
                        <div className="btns">
                            <div className = 'button'>BUY TOKENS SOON</div>
                        </div>
                        <ul>
                            <li>
                                <span className='br'>
                                    <h2>Coming soon</h2>
                                    <p>Current Holders</p>
                                </span>
                            </li>
                            <li>
                                <span className='br'>
                                    <h2>100 Million</h2>
                                    <p>Total supply</p>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <h2>coming soon</h2>
                                    <p>Total Volume</p>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="homeRight" data-aos="fade-left">
                        <img src="assets/home_img.png" alt=""  onLoad={onLoad}/>
                        <div className="countDown">
                        <p>Whitelist sale starts in</p>
                            {!showMint ? <Timer setShowMint = {setShowMint} deadLine = {0}/>:
                            <span className='coming'>Coming Soon</span>}
                            
                        
                        </div>
                    </div>
                   
                </div>
                <div className="effect"></div>
                    <div className="effect1"></div>
            </div>
            
        </div>
    )
}
