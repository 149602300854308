import BtnTimer from 'components/timer/BtnTimer';
import { useEffect, useState } from 'react';
import './tokenStaking.scss'

type LoadingType = {
    title?: string
    ICOEnded?: boolean
    paidAmount?: number
    claimedToken?: number
    totalPaidAmount?: number
    tokenPrice?: number
    minContribute?: number
    maxContribute?: number
    dateString?: string
    startTime?: number
    endTime?: number
    claimTime?: number
    hardCap?: number
    loginStatus?: boolean
    setIsLoading?(flag: boolean): void
    contributeUSDC?(category: string, amount: number): void
    claimREKT?(category: string): void
};
export default function StakingCard(
    {
        title,
        ICOEnded,
        paidAmount,
        claimedToken,
        totalPaidAmount,
        tokenPrice,
        minContribute,
        maxContribute,
        dateString,
        startTime,
        claimTime,
        endTime,
        hardCap,
        loginStatus,
        setIsLoading,
        contributeUSDC,
        claimREKT,
    }: LoadingType) {


    const [isStartTimeEnd, setIsStartTimeEnd] = useState(false)
    const [isEndTimeEnd, setIsEndtTimeEnd] = useState(false)

    useEffect(() => {
        let myInterval = setInterval(() => {
            const currentDate: any = Date.now() / 1000;
            if (currentDate < startTime) {
                setIsStartTimeEnd(false)
            }
            else {
                setIsStartTimeEnd(true)
            }

            if (currentDate < endTime) {
                setIsEndtTimeEnd(false)
            }
            else {
                setIsEndtTimeEnd(true)
            }

        }, 0)
        return () => {
            clearInterval(myInterval);
        };
    }, [setIsStartTimeEnd, setIsEndtTimeEnd, startTime, endTime]);
    const [imgCount, setImgCount] = useState(0)
    const onLoad = () => {
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount >= 5) {
            setIsLoading(false)
        }
    }, [setIsLoading, imgCount]);

    const [stakeCount, setStakeCount] = useState<number>(0)
    const [isValid, setIsValid] = useState(0)
    const onChangeVal = (e: any) => {
        if (e.target.value === '0') {
            setIsValid(1)
        } else if (parseFloat(e.target.value) > maxContribute) {
            setIsValid(2)
        } else {
            setIsValid(0)
        }
        setStakeCount(parseFloat(e.target.value))
    }
    
    const [startDay, setStartDay] = useState(0)
    const [startMon, setStartMon] = useState('')

    const [endDay, setEndDay] = useState(0)
    const [endMon, setEndMon] = useState('')

    useEffect(() => {
        let myInterval = setInterval(() => {
            var st = new Date(startTime * 1000)
            setStartDay(parseInt(st.toString().split(' ')[2]))
            setStartMon(st.toString().split(' ')[1])

            var ed = new Date(endTime * 1000)
            setEndDay(parseInt(ed.toString().split(' ')[2]))
            setEndMon(ed.toString().split(' ')[1])
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };

    }, [startTime, endTime, setStartMon, setStartDay, setEndMon, setEndDay]);

    return (
        <div className="stakingWrapper">
            <div className="header">
                <img src="assets/logo.png" alt="" className='logoImg' onLoad={onLoad} />
                <h2>{title || '_'}</h2>
                <img src="assets/IMG.png" alt="" className="bar" onLoad={onLoad} />
            </div>

            <div className="btns">
                <span>
                    <p className='lft'>Paid Amount :</p> <p>{paidAmount.toFixed(2)} USDC</p>
                </span>
                <span>
                    <p className='lft'>Claimed Token : </p> <p>{claimedToken.toFixed(2)} $REKT</p>
                </span>

                <span>
                    <p className='lft'>Reserved Amount :</p> <p>{tokenPrice === 0 ? 0 : (paidAmount / tokenPrice).toFixed(2)} $REKT</p>

                </span>
                <span> <button
                    className="stakeBtn"
                    onClick={() => { claimREKT(title) }}
                    disabled={!loginStatus || !ICOEnded || claimTime > Date.now()}
                >
                    <p>Claim</p>
                </button></span>
                <img src="assets/bar_03.png" alt="" className='bar' onLoad={onLoad} />
            </div>


            <div className="contribute">
                <p>Your contribution (USDC)</p>
                <span>
                    <input
                        type="number"
                        onChange={(e: any) => { onChangeVal(e) }}
                        value={stakeCount}
                        min={0}
                        max={10}
                        required
                        style={{ boxShadow: isValid === 0 ? '0px 0px 5px #ff040400' : '0px 0px 5px #ff3f04' }}
                    />
                    {isValid === 2 && <p className='alrt'>Too Max</p>}
                    {(isValid === 1) && <p className='alrt'>Not Amount</p>}
                    <p>USDC</p>
                    <button
                        className="harvest"
                        disabled={(!isStartTimeEnd || isEndTimeEnd) && title !== 'PRIVATE'}
                        onClick={() => { contributeUSDC(title, stakeCount) }}
                    >
                        {title === 'PRIVATE' ? <p>CONTRIBUTE</p>:

                        !isStartTimeEnd ?
                            <BtnTimer deadLine={startTime} /> :
                            <p>{isEndTimeEnd ? 'Finished' : 'CONTRIBUTE'}</p>
                        }


                    </button>
                </span>
                <p>You'll be refunded any excess tokens when you claim</p>
            </div>

            <div className="state">
                <span>
                    <p className='lft'>Total Reserved Amount</p>
                    <p>{tokenPrice === 0 ? 0 : (totalPaidAmount / tokenPrice).toFixed(2)} $REKT</p>
                </span>
                <span>
                    <p className='lft'>Total Paid Amount</p>
                    <p>{totalPaidAmount.toFixed(2)} USDC</p>
                </span>
                <span>
                    <p className='lft'>Token Price</p>
                    <p>{tokenPrice} USDC/REKT</p>
                </span>
                <span>
                    <p className='lft'>Min Contribute</p>
                    <p>{minContribute} USDC</p>
                </span>
                <span>
                    <p className='lft'>Max Contribute</p>
                    <p>{maxContribute || "UNLIMITED"} USDC</p>
                </span>
                <span>
                    <p className='lft'>Hard Cap</p>
                    <p>{hardCap} USDC</p>
                </span>

                <span>
                    <p className='lft'>Launch Time</p>
                    {title === 'PRIVATE'? 
                    <p>Not Limited</p>:
                    startTime === 0 ? <p>NOT CONFIRMED</p>:
                    (<p>{`${startDay}${startDay === 1 ?'st' : startDay === 2 ? 'nd' :startDay === 3 ?'rd' : 'th'}  ${startMon}` || '_'} to {`${endDay - 1}${startDay === 1 ?'st' : endDay === 2 ? 'nd' :endDay === 3 ?'rd' : 'th'}  ${endMon}` || '_'}</p>)
                    }
                   
                    
                </span>

                <img src="assets/bar_03.png" alt="" className="bar" onLoad={onLoad} />
            </div>

            <img src="assets/Bar_01.png" alt="" className="cardBg" onLoad={onLoad} />
        </div>
    )
}