import { useEffect, useState } from 'react';
import './roadmap.scss'

type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};
export default function RoadMap({setIsLoading}:PropsType) {

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
        
    }
    useEffect(() => {
        if (imgCount >= 5){
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);
    const raodData = [
        {
            level : 'Pre-Launch',
            dec : ['Launch discord ', 'Audit and KYC ', 'Launch of Website and Social Media ', 'Begin Whitelist sale'],
            img : 'assets/road_01.png'

        },
        {
            level : 'Launch',
            dec : ['Launch on Dex ', 'Multiple Global Listings (CG, CMC etc)  ', 'Marketing campaigne 2.0', 'Launch staking pool'],
            img : 'assets/road_02.png'

        },
        {
            level : 'Build & Expand',
            dec : ['Add Cross Chain Capability', 'Launch of our Gold card VIP lounge access NFTS', 'Launch of our gaming token marketplace', 'Launch of our  “Cyber Gaming Room”', 'Team Expansion'],
            img : 'assets/road_03.png'

        },
        {
            level : 'Echosystem and alliencess',
            dec : ['Alliances & Collaborations incubation of complementary projects ', 'Affiliate Sports Betting Partnership with _____.COM (Secret)🦉organization of different investment and opportunities', 'Multi chain Yield Optimiser', ''],
            img : 'assets/road_04.png'

        },
        {
            level : 'Get Rekt or Die Trying ( strategic Acquisitions )',
            dec : ['Strategic Partnerships and Acquisitions in Crypto Gaming Industry', 'Global Outreach and Brand Ambassador Program', 'Launch Mobile Crypto casino'],
            img : 'assets/road_04.png'

        }
    ]
    return (
        <div className="roadmap">
            <div className="scroll" id="roadmap"></div>
            <h1>Road Map</h1>
            <p>more details availble on Gitbook</p>
            <div className="roadmapContent">
                {raodData.map((d,i) =>(
                    <div className="node" key = {i}>
                        <div className="circle" data-aos="fade-left"></div>
                        <div className="line" data-aos="fade-left" style={{width : `calc(${(i + 1) * 10}% - 1rem`}}></div>
                        <div className="level"  data-aos="fade-left"style={{marginLeft : `${(i + 1) * 10}%`, width : '50%'}}>
                            <span> PHASE 0{i + 1}</span> - {d.level}
                        </div>
                        <div className="desc"  data-aos="fade-left">
                            {d.dec.map((e, k)=>(
                                <p key = {k}>{e}</p>
                            ))}

                        <img src="assets/Detail.png" alt="" className="bar"  onLoad={onLoad}/>
                        </div>
                    
                    </div>
                ))}
                </div>
                <div className="effect"></div>
                    <div className="effect1"></div>
        </div>
    )
}
