import './app.scss';
import { useEagerConnect } from "hooks/useEagerConnect";
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import HomePage from 'pages/HomePage';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Toaster } from 'react-hot-toast';
import PrivateSalePage from 'pages/PrivateSalePage';
import WhiteListSalePage from 'pages/WhiteListSalePage';
import PublicSalePage from 'pages/PublicSalePage';
function App() {
  useEagerConnect();
  return (
    <ParallaxProvider>
            <Toaster
              position="top-center"
              toastOptions={{
                success: { duration: 3000 },
                error: { duration: 3000 },
                className : 'myToast'
              }}
              
            />
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/private-sale" component={PrivateSalePage} />
        <Route exact path="/whitelist-sale" component={WhiteListSalePage} />
        <Route exact path="/public-sale" component={PublicSalePage} />
      </Switch>
    </Router>
    </ParallaxProvider>
  );
}

export default App;
