import Loading from 'components/loading/Loading';
import Menu from 'components/menu/Menu';
import Topbar from 'components/topbar/Topbar';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Home from 'components/home/Home';
import About from 'components/about/About';
import RoadMap from 'components/roadmap/RoadMap';
import Creation from 'components/creation/Creation';
import Document from 'components/document/Document';
import Faq from 'components/faq/Faq';
import Community from 'components/community/Community';
import Footer from 'components/footer/Footer';
export default function HomePage( ) {
    const [isHomeLoading, setIsHomeLoading] = useState(true);
    const [isAboutLoading, setIsAboutLoading] = useState(true);
    const [isRoadmapLoading, setIsRoadmapLoading] = useState(true);
    const [isCreationLoading, setIsCreationLoading] = useState(true);
    const [isDocumentLoading, setIsDocumentLoading] = useState(true);
    const [isFaqLoading, setIsFaqLoading] = useState(true);

    const [sectionHeight, setSectionHeight] = useState("0vh");
    const [loadingHeight, setLoadingHeight] = useState(0);

    const [menuOpen, setMenuOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({query: "screen and (max-width: 768px) and (orientation:portrait)",});
    const isLandOrMobile = useMediaQuery({query: "screen and (max-height: 768px) and (orientation:landscape)",});
    useEffect(() => {
        if (isHomeLoading || isAboutLoading || isRoadmapLoading || isCreationLoading || isDocumentLoading || isFaqLoading) {
            setLoadingHeight(100)
            setSectionHeight("0vh")
        }
        else{
            setLoadingHeight(0)
            setSectionHeight("auto")
        }

        if (isTabletOrMobile) {
        }
    
        if (isLandOrMobile) {
        }
        if (!isLandOrMobile && !isTabletOrMobile) {
            setMenuOpen(false);
        }
       
    }, [isHomeLoading, 
        isAboutLoading, 
        isRoadmapLoading, 
        isCreationLoading, 
        isDocumentLoading, 
        isFaqLoading, isTabletOrMobile, isLandOrMobile]);
    AOS.init();
    window.onload = ()=> {
        setIsHomeLoading(false)
        setIsAboutLoading(false)
        setIsRoadmapLoading(false)
        setIsCreationLoading(false)
        setIsDocumentLoading(false)
        setIsFaqLoading(false)

        setTimeout(() => {
            AOS.init({
                // Global settings:
                disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
                disableMutationObserver: false, // disables automatic mutations' detections (advanced)
                debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
                throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
              
                // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
                offset: 120, // offset (in px) from the original trigger point
                delay: 0, // values from 0 to 3000, with step 50ms
                duration: 800, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: false, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
              
              });
        }, 500);
    };
    
    return (
        <>
            <Topbar menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <Menu menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <div className='page'>
                <div className="loding" style = {{width: "100%", height: loadingHeight + "vh", display: loadingHeight === 0? 'none':'flex'}}>
                    <Loading/>
                </div>
                <div className="sections" style = {{width: "100%", height: sectionHeight}}>
                    <Home  setIsLoading = {setIsHomeLoading}/>
                    <About setIsLoading = {setIsAboutLoading}/>
                    <Creation setIsLoading = {setIsCreationLoading}/>
                    <Document setIsLoading = {setIsDocumentLoading}/>
                    <RoadMap setIsLoading = {setIsRoadmapLoading}/>
                    <Faq setIsLoading = {setIsFaqLoading}/>
                    <Community/>
                    <Footer/>
                </div>
                <img src="assets/Texture.png" alt="" className="bg" />
            </div>
        </>
    )
}
