import './community.scss'
export default function Community() {
    return (
        <div className="community">
            <div className="scroll" id="community"></div>
            <div className="communityContent" data-aos="fade-up">
                <div className="wrapper">
                    <h1>Join our Community</h1>
                    <p>Dont forget to join our Discord community, and follow us on social media platforms.</p>
                    <a href="https://discord.gg/UVaHcUrf" target={'_blank'} rel="noreferrer"className = 'button'>Discord</a>
                </div>
                <img src="assets/bar_09.png" alt="" className="bg1" />
                <div className="effect1"></div>
            </div>
        </div>
    )
}
