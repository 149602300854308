import { useEffect, useState } from 'react';
import './creation.scss'

type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};
export default function Creation({setIsLoading}:PropsType) {

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount>=6){
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);
    return (
        <div className="creation" id="creation">
            <div className="creationContent">
                <h1>JACKPOT</h1>
                <p>Theres always a winner with REKT</p>

                <div className="wrapper">
                    <div className="item" data-aos="zoom-in">
                        <div className="itemContent">
                            <div className="imgContainer">
                                <img src="assets/img_01.png" alt=""  onLoad={onLoad}/>

                            </div>
                            <h2>Staking</h2>

                        </div>
                        <img src="assets/bar_06.png" alt="" className="bar"  onLoad={onLoad}/>
                    </div>
                    <div className="item" data-aos="zoom-in">
                        <div className="itemContent">
                            <div className="imgContainer">
                            <img src="assets/img_02.png" alt=""  onLoad={onLoad}/>
                            </div>
                            <h2>Great green candle </h2>

                        </div>
                        <img src="assets/bar_06.png" alt="" className="bar"  onLoad={onLoad}/>
                    </div>
                    <div className="item" data-aos="zoom-in">
                        <div className="itemContent">
                            <div className="imgContainer">
                            <img src="assets/home_img.png" alt=""  onLoad={onLoad}/>
                            </div>
                            <h2>Jackpot</h2>

                        </div>
                        <img src="assets/bar_06.png" alt="" className="bar"  onLoad={onLoad}/>
                    </div>
                </div>
            </div>
            <div className="effect"></div>
                <div className="effect1"></div>
        </div>
    )
}
