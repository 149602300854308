import { useEffect, useState } from "react";
import "./faq.scss"
import Expand from "react-expand-animated";
type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};
export default function Faq({setIsLoading}:PropsType) {

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount>=1){
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);
    const faqData = [
        {
            id:0,
            quetion : "Where do I buy $Rekt",
            answer : "Rekt will be bought from a partner respectable partner Dex-exchange that we have yet to disclose"
        },
        
        {
            id:1,
            quetion : "How do you pay winners?",
            answer : "Winners are automatically paid from the contract once they win the jackpot"
        },
        {
            id:2,
            quetion : "How and when are winners announced?",
            answer : "We will have a live-feed dashboard with all the buys along with the addresses associated with them"
        },
        {
            id:3,
            quetion : "How do I enter?",
            answer : "Simply buy the token, and you are automatically in"
        },
    ]

    const faqPreData = [
        {
            id:0,
            quetion : "Will you have a token public pre-sale and Whitlist sale ?",
            answer : "We will have a Whitelist sale. Up till now, there will be no public pre-sale, however if we feel we are short of hitting our hardcap, or funding goals, we will have a public pre-sale"
        },
        
        {
            id:1,
            quetion : "Will the team be KYCD and the contract be audits?",
            answer : "Yes, we will be fully KYCd and the contract will be Audit by a respectable firm (Solidity/Certik) before Whitelist sale."
        },
        {
            id:2,
            quetion : "Where can I find more information about the project's tokenomics and model?",
            answer : "Our project is explained in detail in both our Gitbook documentation and our Medium articles."
        },
    ]

    const faqTokenData = [
        {
            id:0,
            quetion : "How do I deal with taxation?",
            answer : "Taxation will be dynamic, and it will not correlate to your holdings but rather the percentage left to fill up the jackpot to its maximum cap."
        },
        
        {
            id:1,
            quetion : "Where does the prize money come from?",
            answer : "The prize money is a mixture of profits from farming and taxation of buys and sells"
        },
        {
            id:2,
            quetion : "Will you be burning tokens?",
            answer : "We have no plans to burn tokens, the supply is what we expect to be suitable for the project."
        },
    ]
    const styles = {
        open: { width: "100%" },
        close: { width: "100%" }
    };
    const transitions = ["height", "opacity", "background"];

    const [faqId, setFaqId] = useState(-1);
    const handleClick = (id:number)=>{
        if (faqId !== -1){
            if(faqId === id){
                setFaqId(-1)
            }
            else{
                setFaqId(id)
            }
        }
        else{
            setFaqId(id)
        }

    }
    const [tabId, setTabId] = useState(0);
    return (
        <div className="faq">
            <img src="assets/Coins Floating.png" className="coin" alt="" />
            <div className="faqContent">
            <div className="scroll" id="faq"></div>
                <div className="wrapper">
                    <div className="left" data-aos="fade-right">
                        <h1>Have any Question? </h1>
                        <p>Download the whitepaper and learn about ICO Token, the unique ICO Crypto approach and the team/advisors.</p>
                        <div className="myTab">
                            <div className="tabList">
                                <div 
                                    className={ tabId === 0 ? "tab activeTab" : "tab"}
                                    onClick = {()=>{setTabId(0)}}
                                >General
                                </div>
                                <div 
                                    className={ tabId === 1 ? "tab activeTab" : "tab"}
                                    onClick = {()=>{setTabId(1)}}
                                >Pre-launch</div>
                                <div 
                                    className={ tabId === 2 ? "tab activeTab" : "tab"}
                                    onClick = {()=>{setTabId(2)}}
                                >Token</div>
                               
                            </div>
                            <div className="tabBody">
                                <div className={ tabId === 0 ? "tabContent activeTab" : "tabContent"}>
                                    {faqData.map((d)=>(
                                        <div className="line" 
                                            key = {d.id}
                                            onClick={()=>{handleClick(d.id)}} 
                                            style = {{
                                                backgroundColor : faqId === d.id? "#c104de44":"#c104de33"
                                            }}
                                        >
                                            <div className="question">
                                                <p
                                                    style = {{
                                                        color : faqId === d.id? "#c104de":"#fff"
                                                    }}
                                                >{d.quetion}</p>
                                                <i className="show fas fa-chevron-right" style = {{transform: d.id === faqId? "rotate(90deg)" : "rotate(0deg)"}}></i>
                                            </div>
                                            <Expand
                                                open={faqId === d.id}
                                                duration={300}
                                                styles={styles}
                                                transitions={transitions}
                                            >
                                                <p className="answer">{d.answer}</p>
                                            
                                            </Expand>
                                        </div>
                                    ))}
                                </div>
                                <div className={ tabId === 1 ? "tabContent activeTab" : "tabContent"}>
                                    {faqPreData.map((d)=>(
                                        <div className="line" 
                                            key = {d.id}
                                            onClick={()=>{handleClick(d.id)}} 
                                            style = {{
                                                backgroundColor : faqId === d.id? "#c104de44":"#c104de33"
                                            }}
                                        >
                                            <div className="question">
                                                <p
                                                    style = {{
                                                        color : faqId === d.id? "#c104de":"#fff"
                                                    }}
                                                >{d.quetion}</p>
                                                <i className="show fas fa-chevron-right" style = {{transform: d.id === faqId? "rotate(90deg)" : "rotate(0deg)"}}></i>
                                            </div>
                                            <Expand
                                                open={faqId === d.id}
                                                duration={300}
                                                styles={styles}
                                                transitions={transitions}
                                            >
                                                <p className="answer">{d.answer}</p>
                                            
                                            </Expand>
                                        </div>
                                    ))}
                                </div>
                                <div className={ tabId === 2 ? "tabContent activeTab" : "tabContent"}>
                                    {faqTokenData.map((d)=>(
                                        <div className="line" 
                                            key = {d.id}
                                            onClick={()=>{handleClick(d.id)}} 
                                            style = {{
                                                backgroundColor : faqId === d.id? "#c104de44":"#c104de33"
                                            }}
                                        >
                                            <div className="question">
                                                <p
                                                    style = {{
                                                        color : faqId === d.id? "#c104de":"#fff"
                                                    }}
                                                >{d.quetion}</p>
                                                <i className="show fas fa-chevron-right" style = {{transform: d.id === faqId? "rotate(90deg)" : "rotate(0deg)"}}></i>
                                            </div>
                                            <Expand
                                                open={faqId === d.id}
                                                duration={300}
                                                styles={styles}
                                                transitions={transitions}
                                            >
                                                <p className="answer">{d.answer}</p>
                                            
                                            </Expand>
                                        </div>
                                    ))}
                                </div>
                                <div className={ tabId === 3 ? "tabContent activeTab" : "tabContent"}>
                                    {faqData.map((d)=>(
                                        <div className="line" 
                                            key = {d.id}
                                            onClick={()=>{handleClick(d.id)}} 
                                            style = {{
                                                backgroundColor : faqId === d.id? "#c104de44":"#c104de33"
                                            }}
                                        >
                                            <div className="question">
                                                <p
                                                    style = {{
                                                        color : faqId === d.id? "#c104de":"#fff"
                                                    }}
                                                >{d.quetion}</p>
                                                <i className="show fas fa-chevron-right" style = {{transform: d.id === faqId? "rotate(90deg)" : "rotate(0deg)"}}></i>
                                            </div>
                                            <Expand
                                                open={faqId === d.id}
                                                duration={300}
                                                styles={styles}
                                                transitions={transitions}
                                            >
                                                <p className="answer">{d.answer}</p>
                                            
                                            </Expand>
                                        </div>
                                    ))}
                                </div>
                                <div className={ tabId === 4 ? "tabContent activeTab" : "tabContent"}>
                                    {faqData.map((d)=>(
                                        <div className="line" 
                                            key = {d.id}
                                            onClick={()=>{handleClick(d.id)}} 
                                            style = {{
                                                backgroundColor : faqId === d.id? "#c104de44":"#c104de33"
                                            }}
                                        >
                                            <div className="question">
                                                <p
                                                    style = {{
                                                        color : faqId === d.id? "#c104de":"#fff"
                                                    }}
                                                >{d.quetion}</p>
                                                <i className="show fas fa-chevron-right" style = {{transform: d.id === faqId? "rotate(90deg)" : "rotate(0deg)"}}></i>
                                            </div>
                                            <Expand
                                                open={faqId === d.id}
                                                duration={300}
                                                styles={styles}
                                                transitions={transitions}
                                            >
                                                <p className="answer">{d.answer}</p>
                                            
                                            </Expand>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right" data-aos="fade-left">
                        <img src="assets/Have anyquestions Front image.png" alt=""  onLoad={onLoad}/>
                    </div>
                    
                </div>
            </div>
            <div className="effect"></div>
        </div>
    )
}
