import './footer.scss'
export default function Footer() {
    return (
        <div className="footer">
            <div className="footerContent">
                <div className="wrapper" data-aos="fade-up">
                    <div className="left">
                        <h2>Don't miss out, Stay updated</h2>
                        
                    </div>
                    <div className="right">
                        <p>Keeping up with R.E.K.T together Finance REKT's official outlets and social profiles. Please be careful of scammers who maliciously replicate us!</p>
                        <div className="socialLinks">
                            <a href="https://twitter.com/financerekt?s=21&t=yqXsodl637fPnq_H0TKuiw" target="_blank"rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                            </a> 
                            <a href="https://t.me/REKT_Finance" target="_blank"rel="noreferrer">
                                <i className="fab fa-telegram"></i>
                            </a> 
                            <a href="https://www.youtube.com/channel/UCYde1ZW-MkvSArAYwMv2wpg" target="_blank"rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                            </a> 
                            
                            <a href="https://discord.gg/UVaHcUrf" target="_blank"rel="noreferrer">
                            <i className="fab fa-discord"></i>
                            </a> 
                            
                        </div>
                    </div>
                </div>
                
                <span>© 2022. All rights reserved by REKT finance</span>
            </div>
           
        </div>
    )
}
 