import { useEffect, useState } from 'react';
import './about.scss'

type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};
export default function About({setIsLoading}:PropsType) {

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount>=7){
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);
    
    return (
        <div className="about" id="about">
            <div className="scroll" id="about"></div>
            <div className="aboutContent" data-aos="fade-up">
                <div className="wrapper">
                    <div className="left">
                        <h1>what is R.E.K.T ?</h1>
                        <p>Rekt-Together is an online gamified lottery bringing the success of the Power-Ball to the ease, freedom and global reach of cryptocurrency.</p>

                    </div>
                    <div className="right">
                        <ul>
                            <li>
                                <img src="assets/icon_01.png" alt=""  onLoad={onLoad}/>
                                <p>Percentage of Buys and Sells grows Jackpot quickly</p>
                                <img src="assets/bar_05.png" alt="" className="bar"  onLoad={onLoad}/>
                            </li>
                            <li>
                                <img src="assets/icon_02.png" alt=""  onLoad={onLoad}/>
                                <p>You can win every 10 minutes !</p>
                                <img src="assets/bar_05.png" alt="" className="bar"  onLoad={onLoad}/>
                            </li>
                            <li>
                                <img src="assets/icon_03.png" alt=""  onLoad={onLoad}/>
                                <p>Constant buys cause Perpetual Pump</p>
                                <img src="assets/bar_05.png" alt="" className="bar"  onLoad={onLoad}/>
                            </li>
                        </ul>

                    </div>
                </div>
                <img src="assets/bar_04.png" alt="" className="bg1"  onLoad={onLoad}/>
            </div>
        </div>
    )
}
