import { useWeb3React } from '@web3-react/core';
import CustomDropdown from 'components/dropdown/CustomDropdown';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link'
import { truncateWalletString } from 'utils';
import { getICOEngineInfo } from 'utils/contracts';
import ConnectModal from '../connectModal/ConnectModal';
import { ICOEngineDetail } from "utils/typs";
import './topbar.scss'

type MenuType = {
    menuOpen?: boolean;
    setMenuOpen(flag: boolean): void;
};
export default function Topbar({ menuOpen, setMenuOpen }: MenuType) {
    const [showConnectModal, setShowConnectModal] = useState(false);

    const [loginStatus, setLoginStatus] = useState(false);
    const { connector, library, chainId, account, active } = useWeb3React();
    const [ICOEngineDetail, setICOEngineDetail] = useState<ICOEngineDetail>(null);

    useEffect(() => {
        const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
        setLoginStatus(isLoggedin);
        if (isLoggedin) {
            getICOEngineInfo(account).then(
                (engineInfo: ICOEngineDetail) => {
                    setICOEngineDetail(engineInfo);
                }
            );
        }
    }, [connector, library, account, active, chainId]);
    const router = useHistory ();
    
    const goToPage = (url) => {
        console.log(url)
		if (url === "Private Sale") {
		} else if (url === "Whitelist Sale") {
			const href = "/whitelist-sale";
			router.push(href)
		} else if (url === "Public Sale") {
		}
	};

    return (
        <div className="topbar">
            <div className="logo">
                <HashLink to="/" ><img src="assets/logo.png" alt="" /> <h1>Finance</h1></HashLink>
            </div>
            <div className="navList">
                <ul>
                    
                    <li><HashLink to="/#about" smooth>About</HashLink></li>
                    <li><HashLink to="/#creation" smooth>Features</HashLink></li>
                    <li><HashLink to="/#document" smooth>Innovation</HashLink></li>
                    <li><HashLink to="/#roadmap" smooth>Roadmap</HashLink></li>
                    <li><HashLink to="/#faq" smooth>FAQ</HashLink></li>

                    <li>
                        <CustomDropdown
                            buttonText = "ICO"
                            
                            onClick={(url) => {
                                goToPage(url);
                            }}
                            buttonProps={{
                                className: "navLink imageDropdownButton",
                                color: "transparent",
                            }}
                            dropdownList={[
                                "Whitelist Sale",
                            ]}
                        />
                    </li>
                </ul>
                
            </div>
            <div className="btns">
                <div className="balance">
                    <p>balance : </p>
                    <p><span> {ICOEngineDetail?.USDCBalance?.toFixed(2) || "0"} </span> USDC</p>
                    <img src="assets/top_bar.png" alt="" />
                </div>
                <div
                    className="connectBtn"
                    onClick={() => { !loginStatus && setShowConnectModal(true) }}
                >
                    <p>{loginStatus ? truncateWalletString(account) : "Connect Wallet"}</p>
                </div>

            </div>

            <div className={(menuOpen ? "hamburger active" : "hamburger")} onClick={() => setMenuOpen(!menuOpen)}>
                <span className="line1"></span>
                <span className="line2"></span>
                <span className="line3"></span>
            </div>
            
            <ConnectModal showConnectModal={showConnectModal} setShowConnectModal={setShowConnectModal} />
        </div>
    )
}
