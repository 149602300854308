import { useEffect, useState } from 'react';
import './document.scss'

type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};
export default function Document({setIsLoading}:PropsType) {

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount>=7){
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);
    return (
        <div className="document" id="document">
            <div className="documentContent" data-aos="fade-up">
                <h1>Document</h1>
                <p>Download our Whitepaper or visit our Gitbook for more details </p>
                <div className="wrapper">
                        
                        <ul>
                            <li>
                                <a href="https://docs.rekttogether.finance/who-wants-to-get-r.e.k.t/table-of-contents" target="_blank"rel="noreferrer"><p>Gitbook</p>
                                <img src="assets/Group 259.png" alt=""  onLoad={onLoad}/>
                                <img src="assets/6.png" alt="" className="bar"  onLoad={onLoad}/></a>
                                
                            </li>
                            <li>
                                <a href="https://docs.rekttogether.finance/who-wants-to-get-r.e.k.t/table-of-contents" target="_blank"rel="noreferrer"><p>PRIVACY POLICY</p>
                                <img src="assets/Group 259.png" alt=""  onLoad={onLoad}/>
                                <img src="assets/6.png" alt="" className="bar"  onLoad={onLoad}/></a>

                            </li>
                            <li>
                                <a href="https://docs.rekttogether.finance/who-wants-to-get-r.e.k.t/table-of-contents" target="_blank"rel="noreferrer"><p>TERM OF COIN SALES</p>
                                <img src="assets/Group 259.png" alt=""  onLoad={onLoad}/>
                                <img src="assets/6.png" alt="" className="bar"  onLoad={onLoad}/></a>
                            </li>
                        </ul>
                </div>
                <img src="assets/bar_04.png" alt="" className="bg1"  onLoad={onLoad}/>
            </div>
        </div>
    )
}
