import { useEffect, useState } from 'react';
import StakingCard from './StakingCard';
import './tokenStaking.scss'
import ParallaxAsProp from 'components/parallaxAsProp/ParallaxAsProp';
import { useWeb3React } from '@web3-react/core';
import { claimPrivate, claimPublic, claimWhitelist, contributePrivate, contributePublic, contributeWhitelist, getICOEngineInfo } from 'utils/contracts';
import { ICOEngineDetail } from 'utils/typs';
import toast from 'react-hot-toast';

type LoadingType = {
    setIsLoading?(flag: boolean): void;
};
export default function PrivateSale({ setIsLoading }: LoadingType) {
    const [imgCount, setImgCount] = useState(0)
    const [isCard1Loading, setIsCard1Loading] = useState(true)
    const [isBackLoading, setIsBackLoading] = useState(true)
    const onLoad = () => {
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount >= 2 && !isCard1Loading && !isBackLoading) {
            setTimeout(() => {
                setIsLoading(false)
            }, 2500);
        }
    }, [setIsLoading, imgCount, isCard1Loading, isBackLoading]);

    const [loginStatus, setLoginStatus] = useState(false);
    const { connector, library, chainId, account, active } = useWeb3React();
    const [ICOEngineDetail, setICOEngineDetail] = useState<ICOEngineDetail>(null);

    useEffect(() => {
        const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId]);

    useEffect(() => {
        getICOEngineInfo(account).then(
            (engineInfo: ICOEngineDetail) => {
                setICOEngineDetail(engineInfo);
            }
        );
    }, [account]);

    const contributeUSDC = async (category: string, amountOfUSDC: number) => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }

        if (amountOfUSDC <= 0) {
            toast.error("Contribute amount should be over than 0");
            return;
        }

        const load_toast_id = toast.loading("Please wait for Contribute...");
        try {
            let bSuccess = false
            if (category === "PRIVATE") {
                bSuccess = await contributePrivate(chainId, library.getSigner(), account, amountOfUSDC);
            } else if (category === "WHITELIST") {
                bSuccess = await contributeWhitelist(chainId, library.getSigner(), account, amountOfUSDC);
            } else if (category === "PUBLIC") {
                bSuccess = await contributePublic(chainId, library.getSigner(), account, amountOfUSDC);
            }

            if (bSuccess) {
                toast.success("Contribute Success!");

                setTimeout(() => {
                    getICOEngineInfo(account).then(
                        (engineInfo: ICOEngineDetail) => {
                            setICOEngineDetail(engineInfo);
                        }
                    );
                }, 3000);
            } else {
                toast.error("Contribute Failed!");
            }
        } catch (error) {
            toast.error("Contribute Failed!");
        }
        toast.dismiss(load_toast_id);
    };

    const claimREKT = async (category: string) => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }

        const load_toast_id = toast.loading("Please wait for Claim...");
        try {
            let bSuccess = false
            if (category === "PRIVATE") {
                bSuccess = await claimPrivate(chainId, library.getSigner());
            } else if (category === "WHITELIST") {
                bSuccess = await claimWhitelist(chainId, library.getSigner());
            } else if (category === "PUBLIC") {
                bSuccess = await claimPublic(chainId, library.getSigner());
            }

            if (bSuccess) {
                toast.success("Claim Success!");

                setTimeout(() => {
                    getICOEngineInfo(account).then(
                        (engineInfo: ICOEngineDetail) => {
                            setICOEngineDetail(engineInfo);
                        }
                    );
                }, 3000);
            } else {
                toast.error("Claim Failed!");
            }
        } catch (error) {
            toast.error("Claim Failed!");
        }
        toast.dismiss(load_toast_id);
    };

    return (
        <div className="tokenStaking">
            <div className="scroll" id="token_staking"></div>
            <div className="tokenStakingContent">
                <h1>Private Sale</h1>
                <div className="tokenStakingWrapper" data-aos="zoom-in">
                    <StakingCard
                        title='PRIVATE'
                        ICOEnded={ICOEngineDetail?.ICOEnded || false}
                        paidAmount={ICOEngineDetail?.depositPrivate || 0}
                        claimedToken={ICOEngineDetail?.claimPrivate || 0}
                        totalPaidAmount={ICOEngineDetail?.totalDepositPrivate || 0}
                        tokenPrice={ICOEngineDetail?.pricePrivate || 5}
                        minContribute={ICOEngineDetail?.minContributePrivate || 0}

                        dateString="5th May to 8th May"
                        claimTime={ICOEngineDetail?.claimTimestampPrivate || 0}
                        hardCap={ICOEngineDetail?.hardCapPrivate || 0}

                        setIsLoading={setIsCard1Loading}
                        contributeUSDC={contributeUSDC}
                        claimREKT={claimREKT}
                        loginStatus={loginStatus}
                    />
                </div>

                <div className="icoBox" data-aos="zoom-in">
                    <div className="left">
                        <h2>How to take part</h2>
                        <h3>Before Sale:</h3>
                        <ul>
                            <li>Read the project Whitepaper</li>
                            <li>Get USDC in your Metamask wallet</li>
                            <li>Be active within our community for a chance to win a Whitelist slot and many other prizes</li>
                        </ul>

                        <h3>During Sale:</h3>
                        <ul>
                            <li>If your wallet is Whitelisted, purchase the token on our DApp</li>
                            <li>Your address will be registered along with your allocated tokens</li>
                        </ul>

                        <h3>During launch:</h3>
                        <ul>
                            <li>Your tokens will be airdropped to your wallet on launch day</li>
                            <li>The protocol will be fully activated and you will be able to participate</li>
                        </ul>

                    </div>
                    <div className="right">
                        <img src="assets/IMG00.png" alt="" onLoad={onLoad} />

                    </div>
                    <img src="assets/bar_02.png" alt="" className="bar" onLoad={onLoad} />
                    <img src="assets/bar_33.png" alt="" className="bar_mob" onLoad={onLoad} />
                </div>
            </div>
            <ParallaxAsProp pics={"assets/bg.jpg"} setIsLoading={setIsBackLoading} />
        </div>
    )
}