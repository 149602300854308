import "@ethersproject/shims"
import { currentNetwork, getContractObj } from ".";
import { ICOEngineDetail } from "./typs";
import { BigNumber, ethers } from "ethers";
import { RPC_URLS } from "./connectors";
import toast from "react-hot-toast";


export async function getICOEngineInfo(account) {
    const jsonProvider = new ethers.providers.JsonRpcProvider(RPC_URLS[currentNetwork]);
    const ICOContract = getContractObj('REKT_ICO', currentNetwork, jsonProvider);
    const USDCContract = getContractObj('USDC', currentNetwork, jsonProvider);

    try {
        const [
            REKTDecimals,
            ICOEnded,
            USDCBalance,

            depositPrivate,
            claimPrivate,
            claimTimestampPrivate,
            totalDepositPrivate,
            pricePrivate,
            minContributePrivate,
            hardCapPrivate,

            depositWhitelist,
            claimWhitelist,
            claimTimestampWhitelist,
            totalDepositWhitelist,
            priceWhitelist,
            minContributeWhitelist,
            maxContributeWhitelist,
            startTimestampWhitelist,
            endTimestampWhitelist,
            hardCapWhitelist,

            depositPublic,
            claimPublic,
            claimTimestampPublic,
            totalDepositPublic,
            pricePublic,
            minContributePublic,
            maxContributePublic,
            startTimestampPublic,
            endTimestampPublic,
            hardCapPublic,
        ] = await Promise.all([
            ICOContract.REKT_TOKEN_DECIMALS(),
            ICOContract.ICO_ENDED(),
            account ? USDCContract.balanceOf(account) : BigNumber.from(0),

            account ? ICOContract.MAP_DEPOSIT_PRIVATE(account) : BigNumber.from(0),
            account ? ICOContract.MAP_CLAIM_PRIVATE(account) : BigNumber.from(0),
            account ? ICOContract.MAP_CLAIM_DATETIME_PRIVATE(account) : BigNumber.from(0),
            ICOContract.TOTAL_DEPOSIT_PRIVATE(),
            ICOContract.REKT_PRICE_PRIVATE(),
            ICOContract.MIN_CONTRIBUTE_PRIVATE(),
            ICOContract.HARD_CAP_PRIVATE(),

            account ? ICOContract.MAP_DEPOSIT_WHITELIST(account) : BigNumber.from(0),
            account ? ICOContract.MAP_CLAIM_WHITELIST(account) : BigNumber.from(0),
            account ? ICOContract.MAP_CLAIM_DATETIME_WHITELIST(account) : BigNumber.from(0),
            ICOContract.TOTAL_DEPOSIT_WHITELIST(),
            ICOContract.REKT_PRICE_WHITELIST(),
            ICOContract.MIN_CONTRIBUTE_WHITELIST(),
            ICOContract.MAX_CONTRIBUTE_WHITELIST(),
            ICOContract.START_DATETIME_WHITELIST(),
            ICOContract.END_DATETIME_WHITELIST(),
            ICOContract.HARD_CAP_WHITELIST(),

            account ? ICOContract.MAP_DEPOSIT_PUBLIC(account) : BigNumber.from(0),
            account ? ICOContract.MAP_CLAIM_PUBLIC(account) : BigNumber.from(0),
            account ? ICOContract.MAP_CLAIM_DATETIME_PUBLIC(account) : BigNumber.from(0),
            ICOContract.TOTAL_DEPOSIT_PUBLIC(),
            ICOContract.REKT_PRICE_PUBLIC(),
            ICOContract.MIN_CONTRIBUTE_PUBLIC(),
            ICOContract.MAX_CONTRIBUTE_PUBLIC(),
            ICOContract.START_DATETIME_PUBLIC(),
            ICOContract.END_DATETIME_PUBLIC(),
            ICOContract.HARD_CAP_PUBLIC(),
        ]);

        const ICOEngine: ICOEngineDetail = {
            ICOEnded: ICOEnded,
            USDCBalance: parseFloat(ethers.utils.formatUnits(USDCBalance, 6)),

            depositPrivate: parseFloat(ethers.utils.formatUnits(depositPrivate, 6)),
            claimPrivate: parseFloat(ethers.utils.formatUnits(claimPrivate, REKTDecimals)),
            claimTimestampPrivate: claimTimestampPrivate.toNumber(),
            totalDepositPrivate: parseFloat(ethers.utils.formatUnits(totalDepositPrivate, 6)),
            pricePrivate: parseFloat(ethers.utils.formatUnits(pricePrivate, 6)),
            minContributePrivate: parseFloat(ethers.utils.formatUnits(minContributePrivate, 6)),
            hardCapPrivate: parseFloat(ethers.utils.formatUnits(hardCapPrivate, 6)),

            depositWhitelist: parseFloat(ethers.utils.formatUnits(depositWhitelist, 6)),
            claimWhitelist: parseFloat(ethers.utils.formatUnits(claimWhitelist, REKTDecimals)),
            claimTimestampWhitelist: claimTimestampWhitelist.toNumber(),
            totalDepositWhitelist: parseFloat(ethers.utils.formatUnits(totalDepositWhitelist, 6)),
            priceWhitelist: parseFloat(ethers.utils.formatUnits(priceWhitelist, 6)),
            minContributeWhitelist: parseFloat(ethers.utils.formatUnits(minContributeWhitelist, 6)),
            maxContributeWhitelist: parseFloat(ethers.utils.formatUnits(maxContributeWhitelist, 6)),
            startTimestampWhitelist: startTimestampWhitelist.toNumber(),
            endTimestampWhitelist: endTimestampWhitelist.toNumber(),
            hardCapWhitelist: parseFloat(ethers.utils.formatUnits(hardCapWhitelist, 6)),

            depositPublic: parseFloat(ethers.utils.formatUnits(depositPublic, 6)),
            claimPublic: parseFloat(ethers.utils.formatUnits(claimPublic, REKTDecimals)),
            claimTimestampPublic: claimTimestampPublic.toNumber(),
            totalDepositPublic: parseFloat(ethers.utils.formatUnits(totalDepositPublic, 6)),
            pricePublic: parseFloat(ethers.utils.formatUnits(pricePublic, 6)),
            minContributePublic: parseFloat(ethers.utils.formatUnits(minContributePublic, 6)),
            maxContributePublic: parseFloat(ethers.utils.formatUnits(maxContributePublic, 6)),
            startTimestampPublic: startTimestampPublic.toNumber(),
            endTimestampPublic: endTimestampPublic.toNumber(),
            hardCapPublic: parseFloat(ethers.utils.formatUnits(hardCapPublic, 6)),
        }

        return ICOEngine;
    } catch (e) {
        console.log(e);
        return null;
    }
}

/*************** CONTRIBUTE ***************/

export async function contributePrivate(chainId, provider, account, amountOfUSDC: number) {
    const ICOContract = getContractObj('REKT_ICO', chainId, provider);
    const USDCContract = getContractObj('USDC', chainId, provider);
    try {
        const depositUSDC: BigNumber = ethers.utils.parseUnits(amountOfUSDC.toString(), 6);
        const minContribute: BigNumber = await ICOContract.MIN_CONTRIBUTE_PRIVATE();
        const currentDeposit: BigNumber = await ICOContract.MAP_DEPOSIT_PRIVATE(account);
        const isPrivated = await ICOContract.MAP_PRIVATE(account);

        if (!isPrivated) {
            toast.error("Your wallet is not registered");
            return false;
        }

        if (minContribute.gt(currentDeposit.add(depositUSDC))) {
            toast.error("Deposit Amount should be higher than Min Contribute.");
            return false;
        }

        const USDCAllowance = await USDCContract.allowance(account, ICOContract.address);

        if (USDCAllowance.lt(depositUSDC)) {
            const tx1 = await USDCContract.approve(ICOContract.address, ethers.constants.MaxUint256);
            await tx1.wait(1);
        }

        const tx = await ICOContract.contributeForPrivate(depositUSDC);
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function contributeWhitelist(chainId, provider, account, amountOfUSDC: number) {
    const ICOContract = getContractObj('REKT_ICO', chainId, provider);
    const USDCContract = getContractObj('USDC', chainId, provider);
    try {
        const depositUSDC: BigNumber = ethers.utils.parseUnits(amountOfUSDC.toString(), 6);
        const minContribute: BigNumber = await ICOContract.MIN_CONTRIBUTE_WHITELIST();
        const maxContribute: BigNumber = await ICOContract.MAX_CONTRIBUTE_WHITELIST();
        const currentDeposit: BigNumber = await ICOContract.MAP_DEPOSIT_WHITELIST(account);
        const isWhitelisted = await ICOContract.MAP_WHITELIST(account);

        if (!isWhitelisted) {
            toast.error("Your wallet is not registered");
            return false;
        }

        if (minContribute.gt(currentDeposit.add(depositUSDC))) {
            toast.error("Deposit Amount should be higher than Min Contribute.");
            return false;
        }

        if (maxContribute.lt(currentDeposit.add(depositUSDC))) {
            toast.error("Deposit Amount is over than Max Contribute.");
            return false;
        }

        const USDCAllowance = await USDCContract.allowance(account, ICOContract.address);
        if (USDCAllowance.lt(depositUSDC)) {
            const tx1 = await USDCContract.approve(ICOContract.address, ethers.constants.MaxUint256);
            await tx1.wait(1);
        }

        const tx = await ICOContract.contributeForWhitelist(depositUSDC);
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function contributePublic(chainId, provider, account, amountOfUSDC: number) {
    const ICOContract = getContractObj('REKT_ICO', chainId, provider);
    const USDCContract = getContractObj('USDC', chainId, provider);
    try {
        const depositUSDC: BigNumber = ethers.utils.parseUnits(amountOfUSDC.toString(), 6);
        const minContribute: BigNumber = await ICOContract.MIN_CONTRIBUTE_PUBLIC();
        const maxContribute: BigNumber = await ICOContract.MAX_CONTRIBUTE_PUBLIC();
        const currentDeposit: BigNumber = await ICOContract.MAP_DEPOSIT_PUBLIC(account);

        if (minContribute.gt(currentDeposit.add(depositUSDC))) {
            toast.error("Deposit Amount should be higher than Min Contribute.");
            return false;
        }

        if (maxContribute.lt(currentDeposit.add(depositUSDC))) {
            toast.error("Deposit Amount is over than Max Contribute.");
            return false;
        }

        const USDCAllowance = await USDCContract.allowance(account, ICOContract.address);
        if (USDCAllowance.lt(depositUSDC)) {
            const tx1 = await USDCContract.approve(ICOContract.address, ethers.constants.MaxUint256);
            await tx1.wait(1);
        }

        const tx = await ICOContract.contributeForPublic(depositUSDC);
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

/*************** CLAIM ***************/

export async function claimPrivate(chainId, provider) {
    const ICOContract = getContractObj('REKT_ICO', chainId, provider);
    try {
        const tx = await ICOContract.claimPrivate();
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function claimWhitelist(chainId, provider) {
    const ICOContract = getContractObj('REKT_ICO', chainId, provider);
    try {
        const tx = await ICOContract.claimWhitelist();
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function claimPublic(chainId, provider) {
    const ICOContract = getContractObj('REKT_ICO', chainId, provider);
    try {
        const tx = await ICOContract.claimPublic();
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}
