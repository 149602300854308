import { Parallax} from 'react-scroll-parallax';
import './parallaxAsProp.scss'
interface props{
    pics : string
    setIsLoading ? (flag:boolean):void;
}
export default function ParallaxAsProp({pics, setIsLoading}:props) {
    const onLoadImg = ()=>{
        setIsLoading(false)
    }
    return (
        <Parallax
            className="parallaxAsProp"
            speed={-250}
            // slowerScrollRate={false}
            // tag="figure"
            >
            <img src = {pics} alt="" onLoad={onLoadImg}/>
        </Parallax>
    )
}
